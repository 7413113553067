// src/app/app.component.ts
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from './core/services/dialog/dialog.service';
import { FcmService } from './core/services/notification/fcm.service';
import { PushNotificationsService } from './core/services/notification/push-notifications.service';
import { UpdateService } from './core/services/pwa/update.service';
import { AuthService } from './modules/auth/services/auth/auth.service';
import { CompanySettingsService } from './modules/settings/services/company-settings.service';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TimeRecordingService } from './modules/time-recording/services/time-recording.service';
import {
  ActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FixPlaner';
  isMobile: boolean;
  currentRoute: string;
  isLoggedin: boolean;

  private appStateSubscription: Subscription;
  private visibilityChangeHandler: () => void;
  private focusHandler: () => void;

  // Konfigurierbare Schwelle (in Millisekunden)
  private refreshThreshold: number = 5 * 60 * 1000; // 5 Minuten

  // Variable zum Speichern des Zeitpunkts, wann die App in den Hintergrund geht
  private backgroundTimestamp: number | null = null;

  constructor(
    private notification: PushNotificationsService,
    private updateService: UpdateService,
    translate: TranslateService,
    private companySettings: CompanySettingsService,
    private fcmService: FcmService,
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private platform: Platform,
    private timeRecordingService: TimeRecordingService
  ) {
    // Sprach-Initialisierung
    const userLanguage = navigator.language.split('-');
    translate.setDefaultLang(userLanguage[0]);
    translate.use(localStorage.getItem('lang') || userLanguage[0]);

    // Initialisierungen

    // Check: Sind wir native oder web?
    const isNative = Capacitor.getPlatform() !== 'web';

    if (isNative) {
      console.log('in Natives Push');
      // Natives Push
      this.fcmService.initPush();
    } else {
      // Web-Push
      console.log('in Web-Push');
      this.notification.requestPermission();
    }

    this.updateService.checkForUpdates();
    this.companySettings.getCompanySettings();

    // Router-Event-Abonnement zur Überwachung der aktuellen Route
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const urlSegments = event.urlAfterRedirects.split('/');
        const routePath = urlSegments[urlSegments.length - 1];
        this.currentRoute = routePath;

        // Überprüfe, ob der Benutzer eingeloggt ist, basierend auf der aktuellen Route
        const publicRoutes = [
          'login',
          'registration',
          'forgotpassword',
          'reset-password',
          'email-confirmation',
          'change-password',
          'resetpassword',
          'accept-invitation',
          'payment-confirmation',
        ];

        this.isLoggedin = !publicRoutes.some((route) =>
          this.currentRoute.startsWith(route)
        );

        // Dialog-Management basierend auf der Route
        if (this.currentRoute === 'registered-stepper') {
          this.dialogService.setDialogOpen(true); // Dialog geöffnet
        } else {
          this.dialogService.setDialogOpen(false); // Dialog geschlossen
        }

        this.changeDetectorRef.detectChanges();
      });

    console.log('in App-Component');
  }

  ngOnInit() {
    // Bildschirmgröße überwachen
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        this.isMobile = !result.matches;
      });

    // Dialog-Status überwachen und Navbar entsprechend anpassen
    this.dialogService.dialogOpen$.subscribe((isOpen) => {
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        if (isOpen) {
          navbar.classList.add('navbar-disabled');
        } else {
          navbar.classList.remove('navbar-disabled');
        }
      }
    });

    // Initialisiere die App (z.B. Event-Listener)
    this.initializeApp();
  }

  ngOnDestroy(): void {
    // Entferne Event-Listener, wenn die Komponente zerstört wird
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandler
    );
    window.removeEventListener('focus', this.focusHandler);
    PushNotifications.removeAllListeners();
  }

  initializeApp() {
    console.log('in InitailAPP');
    this.platform.ready().then(() => {
      console.log('in InitailAPP neu');
      this.subscribeToAppResume();
    });
  }

  /**
   * Methode zur Überwachung von App Resume und Browser Events
   */
  subscribeToAppResume() {
    console.log('is subscribeToAppResume');
    // Nur machen, wenn wir nativ sind
    if (Capacitor.getPlatform() !== 'web') {
      // 1) AppStateChange: App kommt in den Vordergrund / Hintergrund
      App.addListener('appStateChange', (state) => {
        console.log('Vordergrund oder Hintergrund');
        if (state.isActive) {
          console.log('Vordergrund oder Hintergrund ist ACtive');
          this.performAutomaticRefresh('Capacitor App Resume');
        }
      });

      // 2) Klick auf eine Push-Benachrichtigung
      //    => "pushNotificationActionPerformed"
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async (notification: ActionPerformed) => {
          console.log(
            'User tapped on a push notification: ',
            notification.notification
          );
          // Hier rufst du dein performAutomaticRefresh() auf
          this.performAutomaticRefresh('Notification tap');
        }
      );
    } else console.log('is WEB');

    this.visibilityChangeHandler = () => {
      if (document.hidden) {
        // Seite geht in den Hintergrund
        this.backgroundTimestamp = new Date().getTime();
      } else {
        // Seite kommt in den Vordergrund
        const now = new Date().getTime();
        if (this.backgroundTimestamp) {
          const elapsed = now - this.backgroundTimestamp;
          if (elapsed >= this.refreshThreshold) {
            this.performAutomaticRefresh('Browser visibilitychange');
          }
          // Setze die Hintergrundzeit zurück
          this.backgroundTimestamp = null;
        }

        if (this.isLoggedin) {
          this.timeRecordingService.getEmployeeLastStatus();
        }
      }
    };

    this.focusHandler = () => {
      const now = new Date().getTime();
      if (this.backgroundTimestamp) {
        const elapsed = now - this.backgroundTimestamp;
        if (elapsed >= this.refreshThreshold) {
          this.performAutomaticRefresh('Browser focus');
        }
        // Setze die Hintergrundzeit zurück
        this.backgroundTimestamp = null;
      }
    };

    document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    window.addEventListener('focus', this.focusHandler);
  }

  /**
   * Methode zur Durchführung einer automatischen Aktualisierung
   * @param source Quelle der Aktualisierung (z.B. 'Capacitor App Resume')
   */
  private performAutomaticRefresh(source: string) {
    console.log(
      `Automatische Aktualisierung aus ${source}. Hintergrundzeit: ${
        this.refreshThreshold / 60000
      } Minuten.`
    );
    this.updateService.performRefresh().subscribe({
      next: (results) => {
        console.log('Automatische Aktualisierung erfolgreich:', results);
        // Optional: Weitere Verarbeitung der Ergebnisse
      },
      error: (err) => {
        console.error('Fehler bei der automatischen Aktualisierung:', err);
        // Optional: Zeige eine Fehlermeldung an den Benutzer
      },
    });
  }
}
