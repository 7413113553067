import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { AbsenceRequests } from '../../model/AbsenceRequests.model';
import { AbsenceService } from '../../services/absence.service';
import { AcceptAbsenceComponent } from '../accept-absence/accept-absence.component';

@Component({
  selector: 'app-absence-requests',
  templateUrl: './absence-requests.component.html',
  styleUrls: ['./absence-requests.component.css'],
})
export class AbsenceRequestsComponent implements OnInit {
  private subscriptionEmployeeEventList: Subscription;
  constructor(
    private service: AbsenceService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}
  absenceRequests: AbsenceRequests[];
  ngOnInit(): void {
    this.subscriptionEmployeeEventList =
      this.service.absenceRequestsList.subscribe((list) => {
        const cloned = list.map((x) => Object.assign({}, x));
        cloned.forEach((entry) => {
          const options: any = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          };
          entry.Start = new Date(entry.StarTime);
          entry.End = new Date(entry.EndTime);
          const startDateDay = entry.StarTime.getDate();
          const endDateDay = entry.EndTime.getDate();
          entry.StarTime = new Date(entry.StarTime).toLocaleDateString(
            navigator.language,
            options
          );

          if (startDateDay === endDateDay) {
            entry.dateString =
              "<strong style='white-space: nowrap;'>" +
              entry.StarTime +
              '</strong>';
            if (entry.AllDay) {
              entry.dateString += ' ' + this.translate.instant('ALLDAY');
            } else {
              const startTime = dayjs(entry.Start).format('HH:mm');
              const endTime = dayjs(entry.End).format('HH:mm');
              entry.dateString += '   ' + startTime + '-' + endTime;
            }
          } else {
            entry.EndTime = dayjs(entry.End)
              .subtract(1, 'minute')
              .toDate()
              .toLocaleDateString(navigator.language, options);
            entry.dateString =
              "<strong style='white-space: nowrap;'>" +
              entry.StarTime +
              ' - ' +
              entry.EndTime +
              '</strong>';
          }
        });
        this.absenceRequests = cloned;
      });
  }

  acceptRequest(absenceRequests): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.maxWidth = '95vw';
    dialogConfig.data = {
      Id: absenceRequests.Id,
      EmployeeId: absenceRequests.EmployeeId,
      Title: absenceRequests.Title,
      AbsenceType: absenceRequests.AbsenceType,
      StarTime: absenceRequests.StarTime,
      EndTime: absenceRequests.EndTime,
      End: absenceRequests.End,
      Start: absenceRequests.Start,
      EmployeeName: absenceRequests.EmployeeName,
      AllDay: absenceRequests.AllDay,
      Comment: absenceRequests.Comment,
    };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AcceptAbsenceComponent, dialogConfig);
  }
}
