<div class="row">
  <!-- Spalte für Filter -->
  <div class="col-lg-4 mb-4">
    <form [formGroup]="filterForm">
      <div class="d-flex flex-nowrap align-items-end">
        <!-- Mitarbeiter-Dropdown (flexibel) -->
        <div class="form-group mr-3 flex-grow-1">
          <mat-label>Mitarbeiter</mat-label>
          <mat-select
            formControlName="employee"
            class="form-control"
            (selectionChange)="onInputChange('employee')"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="employeeFilterCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="-1">{{
              "ALL EMPLOYEES" | translate
            }}</mat-option>
            <ng-container *ngFor="let employee of filteredEmployees | async">
              <mat-option [value]="employee.Id">{{ employee.Name }}</mat-option>
            </ng-container>
          </mat-select>
        </div>

        <!-- Jahres-Dropdown (feste Breite über eigene Klasse) -->
        <div class="form-group year-select">
          <mat-label>{{ "YEAR" | translate }}</mat-label>
          <mat-select
            formControlName="year"
            class="form-control"
            (selectionChange)="onInputChange('year')"
          >
            <mat-option *ngFor="let y of yearList" [value]="y">{{
              y
            }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div
        *ngIf="filterForm.hasError('dates')"
        class="alert alert-danger col-12"
        role="alert"
      >
        {{ "Start date should be less than end date" | translate }}
      </div>
    </form>

    <div class="row mt-3">
      <div class="col-lg-12 list-container">
        <app-absence-employee-absence-list
          *ngIf="filterForm.value.employee !== -1"
          [absences]="absenceService.employeeAbsenceList | async"
        >
        </app-absence-employee-absence-list>
      </div>
    </div>
  </div>

  <!-- Spalte für Diagramm -->
  <div class="col-lg-8 mb-4 chart-container">
    <app-absence-holiday-chart
      style="justify-content: center; align-items: center"
      [employeeId]="filterForm.value.employee"
      [year]="filterForm.value.year"
    ></app-absence-holiday-chart>
  </div>
</div>

<!-- Hier unsere neue Liste einbetten -->
