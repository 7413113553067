<div *ngFor="let a of absences" class="event-animation-scale">
  <app-item-list
    [Title]="a.titleString"
    [Content]="a.dateString"
    [ContentColor]="getContentColor(a)"
    [Image]="getIcon(a)"
    [BadgeContent]="getBadgeContent(a)"
    [isButton]="false"
    [isSecondButton]="false"
    [disableTitleMargin]="true"
  ></app-item-list>
</div>
