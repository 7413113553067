import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IonContent, ScrollDetail } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { OpenShiftsService } from 'src/app/modules/admin/services/open-shifts.service';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { ShiftSwapService } from 'src/app/modules/swap-shift/services/shift-swap.service';
import { TimeRecordingService } from 'src/app/modules/time-recording/services/time-recording.service';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';
import { UpdateService } from 'src/app/core/services/pwa/update.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

import dayjs from 'dayjs';

import { AbsenceService } from 'src/app/modules/absence/services/absence.service';

@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.css'],
})
export class EmployeeDashboardComponent implements OnInit, OnDestroy {
  currentTimestamp = new Date().getTime();
  showRefreshText = false;
  showSpinner = false;
  private timerId: any;
  private destroy$ = new Subject<void>();
  private scrollSubscription: Subscription;

  @ViewChild('content', { static: false }) ionContent: IonContent;

  showWelcomeMessage: boolean;
  fadeOut: boolean;
  isMobile: boolean;

  // ========== NEU: Für Jahresauswahl =============
  public year: number = new Date().getFullYear();
  public yearList: number[] = [];

  constructor(
    private calendarService: CalendarService,
    private shiftSwapService: ShiftSwapService,
    private openShiftService: OpenShiftsService,
    public authService: AuthService,
    public bottomNavigationEmployeeService: BottomNavigationEmployeeService,
    private ngZone: NgZone,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private timeRecordingService: TimeRecordingService,
    private updateService: UpdateService,
    public companySettingsService: CompanySettingsService,
    public absenceService: AbsenceService
  ) {}

  ngOnInit(): void {
    const body = document.querySelector('body');
    if (body) {
      this.renderer.setStyle(body, 'overflow-y', 'hidden');
    }

    this.timerId = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - this.currentTimestamp;
      // Zeige Refresh-Button, wenn 2 Stunden seit dem letzten Refresh vergangen sind
      this.showRefreshText = diff > 2 * 60 * 60 * 1000;
      // this.showRefreshText = diff > 8000; // Für Testzwecke
    }, 60000);

    this.checkWelcomeMessage();

    // Scroll-Events (z. B. für Hide-Show-BottomNav)
    this.scrollSubscription = this.bottomNavigationEmployeeService.scrollEvent$
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe((scrollY) => {
        this.ngZone.runOutsideAngular(() => {
          this.handleScroll({
            detail: { scrollTop: scrollY },
          } as CustomEvent<ScrollDetail>);
        });
      });

    // Responsive-Check (Mobile vs. Desktop)
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        this.isMobile = !result.matches;
      });

    // Year-Liste
    const currentYear = new Date().getFullYear();
    for (let y = currentYear - 5; y <= currentYear + 5; y++) {
      this.yearList.push(y);
    }
    // => this.year = currentYear;

    // Erstes Laden der Abwesenheiten:
    this.loadAbsences();
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
    this.destroy$.next();
    this.destroy$.complete();

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.bottomNavigationEmployeeService.setScrollableContentElement(
      this.ionContent
    );
  }

  // Trigger bei Jahresauswahl
  onYearChange(event: any): void {
    this.year = event.value;
    this.loadAbsences();
  }

  /**
   * Lädt die Abwesenheiten für den eingeloggten Mitarbeiter und das ausgewählte year
   * und schreibt sie in absenceService.employeeAbsenceList (BehaviorSubject).
   */
  loadAbsences(): void {
    const employeeId = 0; // oder 0, falls du "aktuell" so definierst
    this.absenceService.loadAbsences(employeeId, this.year);
    // => Liste wird im Template via | async automatisch aktualisiert
  }

  handleRefresh(event) {
    this.showSpinner = true;
    this.currentTimestamp = new Date().getTime();
    this.showRefreshText = false;

    this.updateService.performRefresh().subscribe({
      next: (results) => {
        console.log('Aktualisierungsergebnisse:', results);
        setTimeout(() => {
          event.target.complete();
          this.showSpinner = false;
        }, 1500);
      },
      error: (err) => {
        console.error('Fehler beim Aktualisieren:', err);
        setTimeout(() => {
          event.target.complete();
          this.showSpinner = false;
        }, 1500);
      },
    });
  }

  checkWelcomeMessage(): void {
    const lastLoginTime = localStorage.getItem('lastLoginTime');
    const welcomeMessageShown = localStorage.getItem('welcomeMessageShown');

    if (!lastLoginTime || !welcomeMessageShown) {
      this.showWelcomeMessage = true;
      localStorage.setItem('welcomeMessageShown', 'true');
      this.authService.setLoginTimestamp();
    } else {
      const lastLoginDate = new Date(lastLoginTime);
      const currentDate = new Date();
      const hoursDifference =
        Math.abs(currentDate.getTime() - lastLoginDate.getTime()) / 36e5;

      if (hoursDifference > 24) {
        this.showWelcomeMessage = true;
        localStorage.setItem('welcomeMessageShown', 'true');
        this.authService.setLoginTimestamp();
      }
    }

    if (this.showWelcomeMessage) {
      setTimeout(() => {
        this.fadeOut = true;
      }, 5000); // Starte die Animation nach 5 Sekunden
      setTimeout(() => {
        this.showWelcomeMessage = false;
        this.fadeOut = false;
      }, 10000); // Entferne die Nachricht nach 10 Sekunden
    }
  }

  handleScroll(ev: CustomEvent<ScrollDetail>) {
    this.ngZone.run(() => {
      this.bottomNavigationEmployeeService.emitScrollEvent(ev.detail.scrollTop);
    });
  }
}
