import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Absence } from '../../model/absence.model';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-absence-employee-absence-list',
  templateUrl: './absence-employee-absence-list.component.html',
  styleUrls: ['./absence-employee-absence-list.component.css'],
})
export class AbsenceEmployeeAbsenceListComponent implements OnInit, OnChanges {
  @Input() absences: Absence[] = [];

  // 1) Farb-Konstanten, damit wir die gleiche Zuordnung wie im Chart haben.
  private readonly colorSickDays = 'rgb(159, 43, 0)';
  private readonly colorPendingSickDays = '#E57373';
  private readonly colorPlannedVacation = '#FFA726';
  private readonly colorApprovedVacation = 'rgb(211, 117, 6)';

  // Weil das Chart keinen "Created" oder "Rejected" hat,
  // definieren wir hier sinnvolle Farben selbst:
  private readonly colorCreated = '#9E9E9E'; // grau
  private readonly colorRejected = '#C62828'; // kräftig rot
  private readonly colorFallback = '#a05812';

  constructor() {}

  ngOnInit(): void {
    this.formatAbsences();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['absences']) {
      this.formatAbsences();
    }
  }

  /**
   * Hier bereiten wir Datum & Tage-Anzahl (WorkingDays)
   * nur fürs Frontend auf. Die tatsächliche Berechnung
   * (Wochenenden/Feiertage) läuft im Backend.
   */
  private formatAbsences(): void {
    if (!this.absences) {
      return;
    }

    this.absences.forEach((a) => {
      const start = dayjs(a.StarTime);
      const end = dayjs(a.EndTime);
      const wd = a.WorkingDays;

      // Beispiel: "3 Tage" oder "1 Tag"
      a.titleString = `${wd} ${wd === 1 ? 'Tag' : 'Tage'}`;

      // Datum ein- oder mehrtägig
      if (start.isSame(end, 'day')) {
        a.dateString = start.format('DD.MM.YYYY');
      } else {
        a.dateString = `${start.format('DD.MM.YYYY')} - ${end.format(
          'DD.MM.YYYY'
        )}`;
      }
    });
  }

  /**
   * Zeigt z. B. "Urlaub (genehmigt)" oder "Krankheit (ausstehend)" an.
   */
  getBadgeContent(a: Absence): string {
    const isSick = a.AbsenceType === 2;
    let typeLabel = isSick ? 'Krankheit' : 'Urlaub';

    if (a.AbsenceType === 0) {
      typeLabel = 'Keine Abwesenheit';
    } else if (a.AbsenceType === 3) {
      typeLabel = 'Nicht verfügbar';
    }

    // RequestStatus
    switch (a.RequestStatus) {
      case 1: // Created
        return `${typeLabel} (erstellt)`;
      case 2: // Pending
        return `${typeLabel} (ausstehend)`;
      case 3: // Accepted
        return `${typeLabel}`;
      case 4: // Rejected
        return `${typeLabel} (abgelehnt)`;
      default:
        return typeLabel;
    }
  }

  /**
   * Wählt die Farbe passend zum Chart:
   * - Krank (genehmigt) => SickDays (#B71C1C)
   * - Krank (ausstehend) => PendingSickDays (#E57373)
   * - Urlaub (genehmigt) => Approved (#E65100)
   * - Urlaub (ausstehend) => Planned (#FFA726)
   * - Created => grau
   * - Rejected => kräftig rot
   */
  getContentColor(a: Absence): string {
    // 1) Krank = AbsenceType=2
    if (a.AbsenceType === 2) {
      if (a.RequestStatus === 3) {
        // Accepted => SickDays
        return this.colorSickDays;
      } else if (a.RequestStatus === 2) {
        // Pending => PendingSickDays
        return this.colorPendingSickDays;
      } else if (a.RequestStatus === 1) {
        // Created
        return this.colorCreated;
      } else if (a.RequestStatus === 4) {
        // Rejected
        return this.colorRejected;
      }
    }
    // 2) Urlaub = AbsenceType=1
    else if (a.AbsenceType === 1) {
      if (a.RequestStatus === 3) {
        // Accepted => Approved
        return this.colorApprovedVacation;
      } else if (a.RequestStatus === 2) {
        // Pending => Planned
        return this.colorPlannedVacation;
      } else if (a.RequestStatus === 1) {
        // Created
        return this.colorCreated;
      } else if (a.RequestStatus === 4) {
        // Rejected
        return this.colorRejected;
      }
    }

    // 3) Sonstige Typen/Status => Fallback
    return this.colorFallback;
  }

  /**
   * Wähle ein Icon/Bild abhängig vom Abwesenheits-Typ
   * (unabhängig vom Status).
   */
  getIcon(a: Absence): string {
    switch (a.AbsenceType) {
      case 2: // Sick
        return './assets/icons/sick.png';
      case 1: // Vacation
        return './assets/icons/holiday.png';
      case 3: // NotAvailable => eigenes Icon?
        return './assets/icons/not_available.png';
      default: // 0=Nothing
        return './assets/icons/holiday.png';
    }
  }
}
