import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmployeeListByDateDto } from 'src/app/modules/admin/model/department-dto.model';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-absence-all-employee-chart',
  templateUrl: './absence-all-employee-chart.component.html',
  styleUrls: ['./absence-all-employee-chart.component.css'],
})
export class AbsenceAllEmployeeChartComponent implements OnInit, OnDestroy {
  public filteredEmployees: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);

  public employeeFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  // Das Form mit "employee" und "year"
  public filterForm: FormGroup;

  public employeeList: EmployeeListByDateDto[] = [];
  // Entfernt: public employeeAbsences: Absence[] = []; // Brauchen wir nicht mehr

  // Stelle dir hier ein Jahr-Array bereit oder generiere es dynamisch
  public yearList: number[] = [];

  constructor(
    private employeeService: EmployeeService,
    private absenceService: AbsenceService
  ) {}

  ngOnInit(): void {
    // Beispiel: Erzeuge Year-Liste
    const currentYear = new Date().getFullYear();
    for (let y = currentYear - 5; y <= currentYear + 5; y++) {
      this.yearList.push(y);
    }

    this.filterForm = new FormGroup({
      employee: new FormControl(-1),
      year: new FormControl(currentYear),
    });

    // Mitarbeiterliste vom Service laden
    this.employeeService.employeeNameList.subscribe((list) => {
      this.employeeList = list as EmployeeListByDateDto[];
      // doppelte entfernen
      this.employeeList = this.employeeList.filter(
        (v, i, a) => a.findIndex((t) => t.Id === v.Id) === i
      );
      this.filteredEmployees.next(this.employeeList.slice());
    });

    // Such-Filter für Mitarbeiter
    this.employeeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmployees();
      });

    // Mitarbeiterliste initial laden
    this.employeeService.getEmployeeNameList();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterEmployees(): void {
    if (!this.employeeList) return;

    let search = this.employeeFilterCtrl.value;
    if (!search) {
      this.filteredEmployees.next(this.employeeList.slice());
      return;
    }
    search = search.toLowerCase();
    this.filteredEmployees.next(
      this.employeeList.filter((employee) =>
        employee.Name.toLowerCase().includes(search)
      )
    );
  }

  /**
   * Diese Methode wird aufgerufen, wenn Employee oder Year gewechselt wird.
   * Statt selbst abzusubscriben, rufen wir eine Service-Methode auf,
   * die die `employeeAbsenceListSource` füllt.
   */
  onInputChange(field: string): void {
    const employeeId = this.filterForm.value.employee; // -1 oder ID
    const year = this.filterForm.value.year;

    // Falls du "alle" = -1 erlaubst, kannst du
    //   - entwerder garnichts laden
    //   - oder Service "0" rufen
    //   - oder extra Endpunkt "all employees"?
    // Hier laden wir es einfach:
    this.absenceService.loadAbsences(employeeId, year);
  }
}
