import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { ApiEndPoints, ApiMethod } from '../const';
import { HttpService } from '../http/http.service';
import { FCM } from '@capacitor-community/fcm';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  constructor(private httpClient: HttpService) {}

  async initPush() {
    if (Capacitor.getPlatform() !== 'web') {
      this.registerPush();
      this.sendTokenToServer();
    }
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register()
          .then(() => {
            // Register with Apple / Google to receive push via APNS/FCM
            // check if android
            if (Capacitor.getPlatform() === 'android') {
              PushNotifications.deleteChannel({
                id: 'PushDefaultForeground',
              }).then((removed) => {});

              PushNotifications.createChannel({
                id: 'fixplaner',
                name: 'FixPlanerNotifications',
                description:
                  'FixPlanerNotifications Channel for Push Notifications on Android',
                importance: 5,
                visibility: 1,
                sound: 'notification',
                vibration: true,
                lights: true,
              }).then((created) => {
                console.log('Channel created: ', created);
              });
            }
          })
          .catch((error) => {
            // Handle registration error
            console.log(error);
          });
      } else {
        // Show some error
        console.log('Push permission not granted');
      }
    });

    PushNotifications.addListener('registration', async ({ value }) => {
      let token = value; // Push token for Android

      console.log('Token' + token);
      // Get FCM token instead the APN one returned by Capacitor
      if (Capacitor.getPlatform() === 'ios') {
        const { token: fcm_token } = await FCM.getToken();
        token = fcm_token;
      }
      // Work with FCM_TOKEN
      localStorage.setItem('pushToken', token);

      // save platform to local storage
      localStorage.setItem('platform', Capacitor.getPlatform());
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {}
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log(
          'Action performed: ' + JSON.stringify(notification.notification)
        );
        if (data.detailsId) {
        }
      }
    );
  }

  sendTokenToServer() {
    // get the token from the local storage and send it to the server to save it
    const token = localStorage.getItem('pushToken');

    // get the platform from the local storage and send it to the server to save it
    const platform = localStorage.getItem('platform');

    // check if logged in and send the token to the server
    if (token && localStorage.getItem('token')) {
      this.AddAccessToken(token, platform);
    }
  }

  AddAccessToken(accessToken, platform): void {
    return this.httpClient
      .requestCall(ApiEndPoints.AddAccessToken, ApiMethod.POST, {
        AccessToken: accessToken,
        Platform: platform,
      })
      .subscribe((res: any) => {});
  }
}
