import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordConfirmationValidatorService } from 'src/app/core/custom-validators/password-confirmation-validator.service';
import { UserCompany } from '../../models/user-company';
import { AuthService } from '../../services/auth/auth.service';
import { Observable } from 'rxjs';
import { AvailableSubscriptionDto } from 'src/app/modules/feature-toggle/model/available-subscription-dto.model';
import { SubscriptionService } from 'src/app/modules/feature-toggle/services/subscription.service';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css'],
})
export class RegisterUserComponent implements OnInit {
  registerForm: UntypedFormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  availableSubscriptions$: Observable<AvailableSubscriptionDto[]>;
  selectedSubscription: AvailableSubscriptionDto;

  constructor(
    private authService: AuthService,
    private passConfValidator: PasswordConfirmationValidatorService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.registerForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.validatePasswordStrength.bind(this),
      ]),
      companyName: new UntypedFormControl('', Validators.required),
      confirmPassword: new UntypedFormControl('', Validators.required),
    });

    this.registerForm
      .get('confirmPassword')
      ?.setValidators([
        Validators.required,
        this.passConfValidator.validateConfirmPassword(
          this.registerForm.get('password')!
        ),
      ]);

    this.loadAvailableSubscriptions();

    // Check if the URL has a subscription ID
    ///register?subscriptionId=3
    const subscriptionId = this.route.snapshot.queryParams['subscriptionId'];
    if (subscriptionId) {
      this.availableSubscriptions$.subscribe((subscriptions) => {
        this.selectedSubscription = subscriptions.find(
          (sub) => sub.Id === +subscriptionId
        );
      });
    } else {
      this.availableSubscriptions$.subscribe((subscriptions) => {
        this.availableSubscriptions$.subscribe((subscriptions) => {
          this.selectedSubscription = subscriptions.find((sub) => sub.Id === 3);
        });
      });
    }
  }

  loadAvailableSubscriptions(): void {
    this.availableSubscriptions$ =
      this.subscriptionService.loadAvailableSubscriptions();
  }

  selectSubscription(subscription: AvailableSubscriptionDto): void {
    this.selectedSubscription = subscription;
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  validatePasswordStrength(
    control: UntypedFormControl
  ): { [key: string]: boolean } | null {
    const value = control.value;
    console.log('Validating password:', value);

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>\-]/.test(value); // Beachte das `\-` für das Minuszeichen

    console.log('Has Upper Case:', hasUpperCase);
    console.log('Has Lower Case:', hasLowerCase);
    console.log('Has Numeric:', hasNumeric);
    console.log('Has Special Character:', hasSpecial);

    const passwordValid =
      hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;

    return passwordValid ? null : { passwordStrength: true };
  }

  validateControl(controlName: string): boolean {
    const control = this.registerForm.get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.registerForm.get(controlName)?.hasError(errorName) || false;
  }

  registerUser(): void {
    if (this.registerForm.valid) {
      this.spinner.show();
      const formValues = this.registerForm.value;
      const user: UserCompany = {
        id: null,
        FirstName: formValues.firstName,
        LastName: formValues.lastName,
        Email: formValues.email,
        Password: formValues.password,
        CompanyName: formValues.companyName,
        SubscriptionPlanId: this.selectedSubscription?.Id,
      };

      this.authService.register(user);
    }
  }

  removeSpaces(controlName: string): void {
    const control = this.registerForm.get(controlName);
    if (control && control.value) {
      control.setValue(control.value.trim());
    }
  }
}
